import axios from 'axios'
import { API_URL } from "./config";



const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 60000
});

const APIService = {
    
  
    
    get(resource) {
      //console.log("doing get to "+ resource);
      return apiClient.get(resource);
    },
    
    post(resource, params) {
      return apiClient.post(`${resource}`, params);
    },
  };
  
  export default APIService;